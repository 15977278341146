/* Add this CSS to style your login page */
.login-container {
  background-image: linear-gradient(249.89deg,#0398dc 1.75%,#a30463 87.87%);
  display: flex;
  height: 100%;
  width: 100%;
}

/* .login-box {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
} */

h2 {
  text-align: center;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  font-weight: bold;
}

input[type="text"],
input[type="password"] {
  border: none;
border-bottom: 2px solid #87b2c6;
margin-top: 10px;
padding: 5px 0;
width: 100%;
}

button {
  width: 200px;
  padding: 10px;
  background-color: #169ad7;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  margin-right: 10px;
}


.active {
  background-color: #0056b3;
}

button:hover {
  background-color: #0056b3;
}

.error {
  color: red;
  text-align: center;
  margin-top: 10px;
}

.admin-portal {
  height: 100vh;
  margin: 0;
  overflow: hidden;
  padding: 0;
  width: 100vw;
}

.left-box {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 80%;
}

.right-content {
  height: 100%;
  padding: 0 10vw;
  -webkit-transform: skew(20deg);
  transform: skew(20deg);
}

.right-box {
  background-color: #fff;
  -webkit-transform: skewX(-20deg);
  transform: skewX(-20deg);
}

.whiteCover {
  background-color: #fff;
  height: 100vh;
  position: absolute;
  right: 0;
  top: 0;
  width: 180px;
}

.mac-text {
  color: #fff;
  font-family: Georgia;
  font-size: 2.5em;
  font-weight: 700;
  left: 21%;
  position: absolute;
  top: 55%;
}

.powered-img {
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 10;
}


/* Sidebar Styles */
.sidebar {
  width: 200px;
  height: 100%;
  background-color: rgba(0, 42, 58, 1); /* Sidebar background color */
  color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  padding-top: 20px;
  overflow-y: auto;
  z-index: 1000;

  .sidebar-footer {
    bottom: 0;
    position: fixed;
    padding: 15px;
  }

  .sidebar-footer img {
    width: 50%; /* Adjust the width of the footer image as needed */
    /* Add any other styles you want for the footer image */
  }
}


.sidebar-header {
  text-align: center;
  margin-bottom: 20px;
}

.sidebar-header h3 {
  font-size: 24px;
  margin: 0;
  padding: 10px;
}

.sidebar-menu {
  list-style-type: none;
  padding: 0;
}

.sidebar-menu li {
  margin-bottom: 10px;
}

.sidebar-menu a {
  text-decoration: none;
  color: #fff;
  display: block;
  padding: 10px;
  transition: background-color 0.2s;
  border-left: 3px solid transparent;
}

.sidebar-menu a:hover {
  background-color: #000000; /* Highlight on hover */
  border-left: 3px solid #007bff; /* Highlight color on hover */
}

.content {
  margin-left: 220px; /* Adjust as needed */
  padding: 20px; /* Add padding to content */
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}
